export const BRadius = {
    radius_4 : 4,
    radius_8 : 8,
    radius_10 : 10,
    radius_15 : 15,
    radius_20 : 20,
    radius_25 : 25, 
    radius_50p : '50%',  
}
export const Color = {
    white:"#ffffff",
    Black : '#000000',  
    BlueStone : '#26656A',
    DodgerBlue:"#128FF8",
    ColumbiaBlue:"#9BE2FF",
    AliceBlue :"#F4F7FE",
    Midnight:"#1F263E",
    FernGreen :"#58BC5A",
    ShamrockGreen:"#32BC94",
    MagicMintGreen:"#9EFBD5",
    CerisePink :"#EB2A6A",
    CarnationPink:"#FFAEC8",
    AmethystParpal:"#9551DD", 
    WisteriaParpal : '#BE8AF5',
    RedOrange : '#F4462F',
    RoseBudOrange : '#FFA99D',
    Honeysuckle : '#E1E569',
    Citron  : '#8F9317',
    DimGray:"#6C6C6C",
    DarkGray : '#AEAEAE',
    WhiteSmoke  : '#F5F5F5',

}

export const BGColor = {
    CerisePink: "linear-gradient(56deg, #E62162 -12.12%, #FF508A 105.72%)",
    ShamrockGreen:"linear-gradient(238deg, #4DE78F -7.67%, #29AD96 103.86%)",
    DodgerBlue:"linear-gradient(67deg, #1288F7 -3%, #11CAFF 102.89%)", 
    AmethystParpal:"linear-gradient(256deg, #A055F3 -7.78%, #6D448B 104.5%)", 
    RedOrange:"linear-gradient(259deg, #FD5F36 -4.45%, #F13D2D 100%)", 

}
export const DFC ={
    JFC : 'justifi'
}

 