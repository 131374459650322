import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
import '../style.css'
import { Color } from '../../theme/theme'
import CountingText from './CountingText'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ApaxChart from '../charts/ApaxChart';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import axios from 'axios';



// const GasCascade = {
//     name: ' ',
//     category: ['23-12-2023', '24-12-2023', '25-12-2023',],
//     title: ["Total time", "Compressor (hour)", "Total Gas", 'SCM'],
//     type: ['bar', 'bar', 'bar', 'bar'],
//     data: [
//         ['2.2', '3.2', '4.2'],
//         ['2.3', '4.3', '1.8'],
//         ['4.5', '2.5', '3.5'],
//         ['2', '3', '4']
//     ],
//     backgroundColor: ['#F4462F', '#128FF8', '#58BC5A', "#fefefe"],
// }

// const GasLine = {
//     name: ' ',
//     category: ['Reading1', 'Reading2', 'Reading3'],
//     title: ["Extra", "Time", "SCM"],
//     type: ['line', 'bar', 'bar'],
//     data: [
//         ['2', '2', '3.5',],
//         ['5.3', '4.3', '3.5'],
//         ['4', '3', '2.5',]
//     ],
//     backgroundColor: ['#F4462F', '#128FF8', '#58BC5A',],

// }

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);


const Category4 = () => {

    const [filterCascade, setFilterCascade] = useState("Day Shift")
    const [startDate1, setStartDate1] = useState(moment().subtract(2, 'days'));
    const [endDate1, setEndDate1] = useState(moment());
    const category4_1Ref = useRef(null);
    const [cascodeResult, setCascodeResult] = useState()
    const [cascodeShiftResult, setcasecodeShiftResult] = useState()
    const [gasCascodeData, setGasCascodeData] = useState()

    // gasline start
    const [startDate2, setStartDate2] = useState(moment().subtract(2, 'days'));
    const [endDate2, setEndDate2] = useState(moment());
    const category4_2Ref = useRef(null);

    const [gasLineData, setGasLineData] = useState()
    const [gasLine, setGasLine] = useState()




    useEffect(() => {
        AOS.init({ duration: 2000 });
    })



    useEffect(() => {
        setcasecodeShiftResult(cascodeResult?.compress_gas_cascade?.filter((el) => el.name == filterCascade))
    }, [filterCascade, cascodeResult])


    function handleCallback1(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate1(formattedStartDate);
        setEndDate1(formattedEndDate);
        category4_1Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate1,
            endDate: endDate1,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category4_1').daterangepicker(options, handleCallback1);
        handleCallback1(startDate1, endDate1);
    }, []);
    useEffect(() => {
        axios.post('https://utpann.in:3000/CompressedGasInCascade', { sdate: startDate1, edate: endDate1 })
            .then((res) => {
                setCascodeResult(res.data?.result)

            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate1, endDate1])


    useEffect(() => {
        let category = []
        let totalGas = []
        let totalScm = []
        let totaltime = []
        for (let i = 0; i < cascodeShiftResult?.[0]?.compress_gas_cascade?.length; i++) {
            category.push(cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].date == null ? 0 : cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].date)
            totalGas.push(cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_gas == null ? 0 : cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_gas)
            totalScm.push(cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_scm == null ? 0 : cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_scm)
            totaltime.push(cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_time == null ? 0 : cascodeShiftResult?.[0]?.compress_gas_cascade?.[i].tot_time.toFixed(2))
        }
        setGasCascodeData({
            name: ' ',
            category: category,
            title: ["Total time", "Total Gas", 'SCM'],
            type: ['bar', 'bar', 'bar'],
            data: [
                totaltime,
                totalGas,
                totalScm,
            ],

        })
    }, [cascodeShiftResult])



    function handleCallback2(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate2(formattedStartDate);
        setEndDate2(formattedEndDate);
        category4_2Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate1,
            endDate: endDate1,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category4_2').daterangepicker(options, handleCallback2);
        handleCallback2(startDate2, endDate2);
    }, []);

    useEffect(() => {
        axios.post('https://utpann.in:3000/GasLine', { date: startDate2, date: endDate2 })
            .then((res) => {
                setGasLineData(res.data?.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate2, endDate2])

    useEffect(() => {
        let category = []
        let time = []
        let endTime = []
        let meterReadding = []
        let endMeterReadding = []
        for (let i = 0; i < gasLineData?.gas_line?.length; i++) {
            category.push(gasLineData?.gas_line?.[i].date == null ? 0 : gasLineData?.gas_line?.[i].date)
            time.push(gasLineData?.gas_line?.[i].time == null ? 0 : gasLineData?.gas_line?.[i].time)
            endTime.push(gasLineData?.gas_line?.[i].end_time == null ? 0 : gasLineData?.gas_line?.[i].end_time)
            meterReadding.push(gasLineData?.gas_line?.[i].meter_reading == null ? 0 : gasLineData?.gas_line?.[i].meter_reading)
            endMeterReadding.push(gasLineData?.gas_line?.[i].end_meter_reading == null ? 0 : gasLineData?.gas_line?.[i].end_meter_reading)
        }
        setGasLine({
            name: ' ',
            category: category,
            title: ["Time", "End-Time", "Meater-Readding", "End-Meter-Readding"],
            type: ['line', 'line', 'bar', 'bar'],
            data: [
                time,
                endTime,
                meterReadding,
                endMeterReadding
            ],

        })


    }, [gasLineData])






    return (
        <div class=''>
            <div class='row col-12 m-auto gap-lg-0 gap-4 '>
                <div class='col-lg-8 bg-white text-start rounded-4 ' data-aos="fade-up">
                    <div class='p-3'>
                        {/* <div class='d-flex align-items-center justify-content-between flex-wrap'>
                    <p class='fw-bold text-nowrap'>Compressed Gas in Cascade</p>

                </div>  */}
                        <div class='row align-items-center justify-content-between gap-lg-0 gap-3 text-start'>
                            <div class='col-lg-6'>
                                <p class='fw-bold'>Compressed Gas in Cascade</p>
                            </div>
                            <div class='col-lg-6 text-end d-flex justify-content-end'>
                                <div ref={category4_1Ref} id="category4_1" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                                <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div class='d-flex gap-2' >
                            <button class={filterCascade == "Day Shift" ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setFilterCascade("Day Shift")}>
                                Day Shift
                            </button>
                            <button class={filterCascade == "Night Shift" ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setFilterCascade("Night Shift")}>
                                Night Shift
                            </button>
                        </div>

                        <div class='row flex-column-reverse flex-lg-row '>
                            <div class='col-lg-9 row m-auto'>
                                <ApaxChart chartData={gasCascodeData} />
                            </div>

                            <div class='col-lg-3'>
                                <div class='border rounded-4 p-2 d-flex align-items-center my-3 ' style={{ background: Color.WhiteSmoke }}>
                                    <div>
                                        <p class='text-secondary fw-bold'>Total SCM</p>
                                        <p><span class='fs-5 text-success fw-bold'><CountingText key={cascodeResult?.total_scm} num={cascodeResult?.total_scm} /></span> <span style={{ fontSize: '12px', color: 'red', fontWeight: '600' }}>{cascodeResult?.per_total_scm}%</span></p>
                                    </div>
                                </div>
                                <div class='border rounded-4 p-2 d-flex align-items-center my-3 ' style={{ background: Color.WhiteSmoke }}>
                                    <div>
                                        <p class='text-secondary fw-bold'>Total PSA (Time)</p>
                                        <p><span class='fs-5 text-success fw-bold'><CountingText key={cascodeResult?.total_time} num={cascodeResult?.total_time} /></span>  </p>

                                        {/* <p><span class='fs-5 text-success fw-bold'><CountingText num={1957.50}/></span> <span style={{fontSize:'12px',color:'red',fontWeight:'600'}}>+2.45%</span></p> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class='col-lg-4 col-12  px-lg-3 p-0 ' >
                    <div class='bg-white text-start rounded-4 w-100 h-100' data-aos="fade-up">
                        <div class='p-3 d-flex justify-content-end'>
                            <div ref={category4_2Ref} id="category4_2" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                            <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                            </div>
                        </div>

                        <div class=''>

                            <div class='m-auto p-3 row'>
                                <div class='col-6'>
                                    <p class='fw-bold'>Gase Line</p>
                                </div>
                                <div class='col-6'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Total SCM</p>
                                    <p><span class='fs-5 text-success fw-bold'><CountingText key={gasLineData?.total_scm} num={gasLineData?.total_scm == null ? 0 : gasLineData?.total_scm} /></span> <span style={{ fontSize: '12px', color: 'red', fontWeight: '600' }}>{gasLineData?.per_total_scm == null ? 0 : gasLineData?.per_total_scm}%</span></p>
                                </div>
                            </div>

                            {/* <div class='m-auto p-3 row'>
                                <div class='col-6'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Starting Time</p>
                                    <p><span class='fw-bold'>01:00 PM</span></p>
                                </div>
                                <div class='col-6'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>End Time</p>
                                    <p><span class='fw-bold'>05:25 PM</span></p>
                                </div>
                            </div> */}

                            {/* <div class='m-auto p-3 row'>
                                <div class='col-6'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Starting Meter Reading</p>
                                    <p><span class='fw-bold'><CountingText num={1210789.00} /></span></p>
                                </div>
                                <div class='col-6'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Ending Meter Reading</p>
                                    <p><span class='fw-bold'><CountingText num={121467.98} /></span></p>
                                </div>
                            </div> */}

                            <div>
                                <ApaxChart chartData={gasLine} />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Category4