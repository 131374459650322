import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
import CountingText from './CountingText';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ApaxChart from '../charts/ApaxChart';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import axios from 'axios';



// const materialFeeded = {
//     name: ' ',
//     category: ['Digester1', 'Digester2', 'Digester3'],
//     title: ["PH", "Tempretur", "Feeding"],
//     type: ['bar', 'bar', 'bar'],
//     data: [
//         ['4.5', '2.5', '3.5'],
//         ['2.3', '4.3', '1.8'],
//         ['2', '3', '4']
//     ],
//     backgroundColor: ['#F4462F', '#128FF8', '#58BC5A',],
// }

// const rawgasOrpuregas = {
//     name: ' ',
//     category: ['Generation1', 'Generation2', 'Generation3'],
//     title: ["Row Gas", "Pure Gas"],
//     type: ['line', 'line'],
//     data: [
//         ['3.1', '4.5', '3.3'],
//         ['4.1', '5.2', '4.3'],

//     ],
//     backgroundColor: ['#F4462F', '#128FF8', '#58BC5A',],
// }

const MFbutton = ["Day Shift", "Night Shift"]

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);


const Category3 = () => {
    // Total Material Feeded in cubic meter start
    const [filterFeedingShift, setFilterFeedingShift] = useState('Day Shift') 
    const [materialFeededCubicMeter, setMaterialFeededCubicMeter] = useState()
    const [feededValue, setfeededValue] = useState()
    const [materialFilter, setMaterialFilter] = useState()

    const [startDate1, setStartDate1] = useState(moment().subtract(1, 'days'));
    const [endDate1, setEndDate1] = useState(moment());
    const category3_1Ref = useRef(null);
    // ----------completed ---------------------

    // Total Raw Gas vs Pure Gas Generation start
    const [shiftGas, setGasShift] = useState("Day Shift")
    const [totalGasGeneration, setTotalGasGeneration] = useState()
    const [gasChart, setGasChart] = useState() 
    const [startDate2, setStartDate2] = useState(moment().subtract(1, 'days'));
    const [endDate2, setEndDate2] = useState(moment());
    const category3_2Ref = useRef(null);

    // Total Material Feeded in cubic meter start 
    useEffect(() => {
        let feedFild = []
        let feedM3 = []
        let feedPh = []
        let feedTamp = []
        let day = []
        let night = []
        for (let i = 0; i < materialFeededCubicMeter?.length; i++) {
            feedFild.push(materialFeededCubicMeter?.[i]?.name)
            feedM3.push(materialFeededCubicMeter?.[i]?.total_feeding_m3 == null ? 0 : materialFeededCubicMeter?.[i]?.total_feeding_m3)
            feedPh.push(materialFeededCubicMeter?.[i]?.ph_reading == null ? 0 : materialFeededCubicMeter?.[i]?.ph_reading)
            feedTamp.push(materialFeededCubicMeter?.[i]?.temperature_reading == null ? 0 : materialFeededCubicMeter?.[i]?.temperature_reading)
            materialFeededCubicMeter?.[i]?.shift_data?.filter((el) => {
                if (el.name == 'Day Shift') {
                    day.push(el.feeding)
                }
            })

            materialFeededCubicMeter?.[i]?.shift_data?.filter((el) => {
                if (el.name == 'Night Shift') {
                    night.push(el.feeding)
                }
            })
        }
        setfeededValue(
            {
                category: feedFild,
                TotalFeeding: feedM3,
                ph: feedPh,
                tamp: feedTamp,
                dayFeeding: day,
                nightFeeding: night
            }
        )

    }, [materialFeededCubicMeter])


    useEffect(() => {
        let feedingData = ''
        if (filterFeedingShift == "Day Shift") {
            feedingData = feededValue?.dayFeeding
        } else {
            feedingData = feededValue?.nightFeeding
        }
        setMaterialFilter({
            name: ' ',
            category: feededValue?.category,
            title: ["PH", "Tempretur", "Feeding"],
            type: ['bar', 'bar', 'bar'],
            data: [feededValue?.ph, feededValue?.tamp, feedingData],

        }
        )
    }, [feededValue, filterFeedingShift])

    const FeedingCalculation = () => {
        let arr = []
        for (let i = 0; i < feededValue?.category?.length; i++) {
            arr.push(
                <div class='col-11 m-auto my-3'>
                    <p class='text-secondary' style={{ fontSize: '12px' }}>{feededValue?.category?.[i]}</p>
                    <p class='fs-5 fw-bold'><CountingText key={feededValue?.TotalFeeding?.[i]} num={feededValue?.TotalFeeding?.[i]} /> </p>
                </div>
            )
        }
        return arr;
    }
    const totalSumFeeding = feededValue?.TotalFeeding.reduce((accumulator, currentValue) => accumulator + currentValue, 0);



    function handleCallback1(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate1(formattedStartDate);
        setEndDate1(formattedEndDate);
        category3_1Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate1,
            endDate: endDate1,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category3_1').daterangepicker(options, handleCallback1);
        handleCallback1(startDate1, endDate1);
    }, []);

    useEffect(() => {
        axios.post('https://utpann.in:3000/MaterialFeededCubicMeter', { sdate: startDate1, edate: endDate1 })
            .then((res) => {
                setMaterialFeededCubicMeter(res.data?.result);
                // console.log(res.data?.result);   
            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate1, endDate1])

    // Total Raw Gas vs Pure Gas Generation start

    function handleCallback2(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate2(formattedStartDate);
        setEndDate2(formattedEndDate);
        category3_2Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate2,
            endDate: endDate2,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category3_2').daterangepicker(options, handleCallback2);
        handleCallback2(startDate2, endDate2);
    }, []);

    useEffect(() => {
        axios.post('https://utpann.in:3000/RawGasPureGasGeneration', { sdate: startDate2, edate: endDate2 })
            .then((res) => {
                setTotalGasGeneration(res.data?.result);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate2, endDate2])

    let total_consumption = []
    useEffect(() => {
        let category = []
        let siftData = []
        for (let i = 0; i < totalGasGeneration?.raw_gas_generated?.length; i++) {
            category.push(totalGasGeneration?.raw_gas_generated?.[i]?.name == null ? 0 : totalGasGeneration?.raw_gas_generated?.[i]?.name)
            total_consumption.push(totalGasGeneration?.raw_gas_generated?.[i]?.total_consumption == null ? 0 : totalGasGeneration?.raw_gas_generated?.[i]?.total_consumption)
            for (let j = 0; j < totalGasGeneration?.raw_gas_generated?.[i].shift_data?.length; j++) {
                if (totalGasGeneration?.raw_gas_generated?.[i].shift_data?.[j]?.name == shiftGas) {
                    siftData.push(totalGasGeneration?.raw_gas_generated?.[i].shift_data?.[j]?.consumption == null ? 0 : totalGasGeneration?.raw_gas_generated?.[i].shift_data?.[j]?.consumption)
                }
            }
        }
        setGasChart({
            name: ' ',
            category: category,
            title: ["Consumption"],
            type: ['line'],
            data: [
                siftData
            ],
        })
    }, [totalGasGeneration, shiftGas])

    const totalGasConsumption = total_consumption?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })
    return (
        <div>
            <div class='row gap-lg-0 gap-4'>
                <div class='col-lg-6 '>
                    <div class='bg-white rounded-4 p-3'>
                        {/* <div class='d-flex justify-content-between align-items-end '>
                        <div><p class='fw-bold text-start'>Total Material Feeded in cubic meter </p></div>
                        <div class='rounded-2' style={{background:'rgba(88, 188, 90, 0.2)'}}><MdBarChart class='m-1' style={{fontSize:'30px',color:Color.FernGreen}}/></div>
                    </div> */}
                        <div class='row align-items-center justify-content-between gap-lg-0 gap-3 text-start'>
                            <div class='col-lg-6'>
                                <p class='fw-bold'>Total Material Feeded in cubic meter</p>
                            </div>
                            <div class='col-lg-6 text-end d-flex justify-content-end'>
                                <div ref={category3_1Ref} id="category3_1" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                                <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div class='row mt-3'>

                            <div class='col-md-8 border-end border-1'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflowX: 'auto' }}>
                                    {MFbutton?.map((el) => {
                                        return (
                                            <button class={el == filterFeedingShift ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setFilterFeedingShift(el)}>
                                                {el}
                                            </button>
                                        )
                                    })
                                    }
                                </div>
                                <div class='my-3'>
                                    <ApaxChart chartData={materialFilter} />
                                </div>
                            </div>

                            <div class='col-md-4 text-start'>
                                {FeedingCalculation()}

                                <div class='col-11 m-auto my-3'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Total</p>
                                    <p class='fs-4 fw-bold text-success'><CountingText key={totalSumFeeding} num={totalSumFeeding} /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='col-lg-6 '>
                    <div class='bg-white rounded-4 p-3'  >
                        {/* <div class='d-flex justify-content-between align-items-end '>
                        <div><p class='fw-bold text-start'>Total Raw Gas vs Pure Gas Generation</p></div>
                        <div class='rounded-2' style={{background:'rgba(88, 188, 90, 0.2)'}}><PiChartLineUpBold  class='m-1' style={{fontSize:'30px',color:Color.FernGreen}}/></div>
                    </div> */}

                        <div class='row align-items-center justify-content-between gap-lg-0 gap-3 text-start'>
                            <div class='col-lg-6'>
                                <p class='fw-bold'>Total Raw Gas vs Pure Gas Generation</p>
                            </div>
                            <div class='col-lg-6 text-end d-flex justify-content-end'>
                                <div ref={category3_2Ref} id="category3_2" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                                <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div class='row mt-3'>

                            <div class='col-md-8 border-end border-1'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflowX: 'auto' }}>

                                    <button class={shiftGas == "Day Shift" ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setGasShift("Day Shift")}>
                                        Day Shift
                                    </button>
                                    <button class={shiftGas == "Night Shift" ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setGasShift("Night Shift")}>
                                        Night Shift
                                    </button>

                                </div>
                                <div class='my-3'>
                                    <ApaxChart chartData={gasChart} />
                                </div>
                            </div>

                            <div class='col-md-4 text-start'>
                                <div class='col-11 m-auto my-3'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Total</p>
                                    <p class='fs-5 fw-bold'><CountingText key={totalGasConsumption} num={totalGasConsumption} /></p>
                                </div>
                                {/* <div class='col-11 m-auto my-3'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Total Raw Gas Generation</p>
                                    <p class='fs-5 fw-bold'><CountingText num={11512.41} /></p>
                                </div> */}
                                <div class='col-11 m-auto my-3'>
                                    <p class='text-secondary' style={{ fontSize: '12px' }}>Total Raw Gas Generation</p>
                                    <p><span class='fs-4 fw-bold text-success'><CountingText key={totalGasGeneration?.total_raw_gas_generated_farmula} num={totalGasGeneration?.total_raw_gas_generated_farmula} /></span> <span class='text-danger' style={{ fontSize: '12px' }}>{totalGasGeneration?.per_total_raw_gas_generated_farmula}%</span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Category3