import React, { useEffect, useRef, useState } from 'react'
import { BGColor, BRadius, Color } from '../../theme/theme'
import { MdBarChart } from "react-icons/md";
import CountingText from './CountingText';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
 




const Category1 = () => {
    const [category1Data, setCategory1Data] = useState()
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const category1Ref = useRef(null);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

    function handleCallback(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        category1Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate,
            endDate: endDate,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category1').daterangepicker(options, handleCallback);
        handleCallback(startDate, endDate);
    }, []);



    useEffect(() => {
        axios.post('https://utpann.in:3000/', { date: startDate, date :endDate })
            .then((res) => {
                // console.log(res.data);
                setCategory1Data(res.data?.result);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate, endDate])


    return (
        <div>
            <div class='bg-white rounded-4 p-3'  >
                <div class='text-start p-2'>
                    <div ref={category1Ref} id="category1" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                    <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                    </div>
                </div>
                <div class='bg-white col-12 m-auto row  rounded-4 justify-content-center gap-lg-0 gap-2 mt-2 ' data-aos="fade-up">
                    <div class='col-lg-7 row p-0 justify-content-center gap-md-0 gap-2'>
                        <div class='col-md-4' data-aos="zoom-in">
                            <div class='h-100 m-auto p-2 rounded-4 text-start fw-semibold' style={{ background: BGColor.DodgerBlue }}>
                                <div class='col-11 m-auto  row align-items-center'>
                                    <div class='col-md-12  col-4 d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', background: 'white', width: '50px', height: '50px', }}>
                                        <MdBarChart style={{ fontSize: '30px', color: Color.DodgerBlue }} />
                                    </div>
                                    <div class='col-md-12 col-9 '>
                                        <p class='mt-3' style={{ fontSize: '14px', color: Color.ColumbiaBlue }}>Gas Generated</p>
                                        <p class='fs-5 fw-bold text-white '>{<CountingText key={category1Data?.total_gas_generate} num={category1Data?.total_gas_generate == null ? 0 : category1Data?.total_gas_generate} />}</p>
                                        <p style={{ fontSize: '14px' }}><span class='text-danger fw-bold'>{category1Data?.per_gas_generate}%</span> <span style={{ color: Color.ColumbiaBlue }}>since last month</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='col-md-4' data-aos="zoom-in">
                            <div class='h-100 m-auto p-2 rounded-4 text-start fw-semibold' style={{ background: BGColor.ShamrockGreen }}>
                                <div class='col-11 m-auto row align-items-center'>
                                    <div class='col-md-12  col-4 d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', background: 'white', width: '50px', height: '50px', }}>
                                        <MdBarChart style={{ fontSize: '30px', color: Color.FernGreen }} />
                                    </div>
                                    <div class='col-md-12 col-9 '>
                                        <p class='mt-3' style={{ fontSize: '14px', color: Color.MagicMintGreen }}>Total Gas Sold</p>
                                        <p class='fs-5 fw-bold text-white '>{<CountingText key={category1Data?.total_gas_sold} num={category1Data?.total_gas_sold == null ? 0 : category1Data?.total_gas_sold} />}</p>
                                        <p style={{ fontSize: '14px' }}><span class='text-danger fw-bold'>{category1Data?.per_gas_sold}%</span> <span style={{ color: Color.MagicMintGreen }}>since last month</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='col-md-4 col-12' data-aos="zoom-in">
                            <div class='h-100 m-auto p-2 rounded-4 text-start fw-semibold' style={{ background: BGColor.CerisePink }}>
                                <div class='col-11 m-auto row align-items-center'>
                                    <div class='col-md-12  col-4 d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', background: 'white', width: '50px', height: '50px', }}>
                                        <MdBarChart style={{ fontSize: '30px', color: Color.CerisePink }} />
                                    </div>
                                    <div class='col-md-12 col-9 '>
                                        <p class='mt-3' style={{ fontSize: '14px', color: Color.CarnationPink }}>Total Gas sold amount</p>
                                        <p class='fs-5 fw-bold text-white '>{<CountingText key={category1Data?.total_gas_sold_amount} num={category1Data?.total_gas_sold_amount == null ? 0 : category1Data?.total_gas_sold_amount} />}</p>
                                        <p style={{ fontSize: '14px' }}><span class='text-white fw-bold'>{category1Data?.per_gas_sold_amount}%</span> <span style={{ color: Color.CarnationPink }}>since last month</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class='col-lg-5' >
                        <div class='row justify-content-center gap-md-0 gap-2'>
                            <div class='col-md-6' data-aos="zoom-in">
                                <div class='py-2 rounded-4 text-start fw-semibold' style={{ background: BGColor.AmethystParpal }}>
                                    <div class='col-11 m-auto d-flex align-items-center gap-1 '>
                                        <div class='d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', background: 'white', width: '50px', height: '50px', }}>
                                            <MdBarChart style={{ fontSize: '30px', color: Color.AmethystParpal }} />
                                        </div>

                                        <div class='px-2'>
                                            <p class='' style={{ fontSize: '14px', color: Color.WisteriaParpal }}>Electricity Consumption</p>
                                            <p class='fs-5 fw-bold text-white '>{<CountingText key={category1Data?.electricity_reading} num={category1Data?.electricity_reading == null ? 0 : category1Data?.electricity_reading} />}</p>
                                            <p style={{ fontSize: '14px' }}><span class='text-white fw-bold'>{category1Data?.per_electricity_reading}%</span> <span style={{ color: Color.WisteriaParpal }}>since last month</span></p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class='col-md-6' data-aos="zoom-in">
                                <div class='py-2 rounded-4 text-start fw-semibold' style={{ background: BGColor.RedOrange }}>
                                    <div class='col-11 m-auto d-flex align-items-center gap-1 '>
                                        <div class='d-flex justify-content-center align-items-center' style={{ borderRadius: '50%', background: 'white', width: '50px', height: '50px', }}>
                                            <MdBarChart style={{ fontSize: '30px', color: Color.RedOrange }} />
                                        </div>

                                        <div class='px-2'>
                                            <p class='' style={{ fontSize: '14px', color: Color.RoseBudOrange }}>Solar Generation </p>
                                            <p class='fs-5 fw-bold text-white '>{<CountingText key={category1Data?.solar_reading} num={category1Data?.solar_reading == null ? 0 : category1Data?.solar_reading} />}</p>
                                            <p style={{ fontSize: '14px' }}><span class='text-white fw-bold'>{category1Data?.per_solar_reading}%</span> <span style={{ color: Color.RoseBudOrange }}>since last month</span></p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class='px-2 mt-2  '>
                            <div class='row justify-content-between p-0 rounded-4 ' data-aos="zoom-in" style={{ background: Color.WhiteSmoke }}>

                                <div class='col-sm-6 row justify-content-between p-0 m-0'>
                                    <div class='col-6 p-2 text-start'>
                                        <p class='text-secondary'>Petrol Used </p>
                                        <p class='fs-4'>{<CountingText key={category1Data?.total_petrol} num={category1Data?.total_petrol == null ? 0 : category1Data?.total_petrol} />}</p>
                                    </div>
                                    <div class='col-6 p-2 text-start'>
                                        <p class='text-secondary'>Diesel Used</p>
                                        <p class='fs-4'>{<CountingText key={category1Data?.total_diesel} num={category1Data?.total_diesel == null ? 0 : category1Data?.total_diesel} />}</p>
                                    </div>
                                </div>

                                <div class='col-sm-6 row justify-content-end p-0 m-0'>
                                    {/* <div class='col-5 p-2 text-start d-flex align-items-center'>
                                    <div class='col-11 m-auto'>
                                        <img src={require('../../images/vector.png')} style={{ width: '100%' }} alt="" />
                                    </div>
                                </div> */}
                                    <div class=' col-11  p-2 text-start rounded-end-4' style={{ background: Color.Honeysuckle }} >
                                        <p class='text-secondary'>Total</p>
                                        <p class='fw-bold'><span class='fs-4'>{<CountingText key={category1Data?.total_fuel_use} num={category1Data?.total_fuel_use == null ? 0 : category1Data?.total_fuel_use} />}</span> <span class='text-danger' style={{ fontSize: '12px' }}>{category1Data?.per_fuel_use}%</span></p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default Category1