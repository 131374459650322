import React, { useState } from 'react'
import { BRadius, Color } from '../theme/theme'
import { TbMenuDeep } from "react-icons/tb";
import './style.css'

const Navbar = () => {
  const today = new Date().toISOString().split('T')[0];  
  let time = new Date().toLocaleTimeString();
  let [currentTime, changeTime] = React.useState(time);
  const [selectedDate, setSelectedDate] = useState(today); 


  function checkTime() {
    time = new Date().toLocaleTimeString();
    changeTime(time);
  }

  setInterval(checkTime, 1000); 
 
  
 
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  return (
    <div class='bg-white p-2 navbar'>
        <div class='col-11 m-auto d-flex align-items-center justify-content-between flex-wrap '>
          <div class='d-flex gap-sm-5 gap-3 align-items-center ' style={{width:'fit-content'}}>
            <div>
              <img src={require('../images/logo.png')} style={{height:'60px'}} alt="" />
            </div>
            <div>
            <div class='align-items-center ' style={{width:'fit-content'}}> 
              <div class='col-12'> 
                  <p class='fs-5 fw-bold'>{currentTime}</p>
              </div>
              {/* <div class='col-12'> 
                <input class='form-control fw-bold' type="date" value={selectedDate} onChange={handleDateChange} />
              </div> */}
            </div>
            </div>
          </div>
          <div class='d-flex align-items-center gap-4'> 
           
                <div class="dropdown navDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{width:'35px',height:'35px',borderRadius:'50%'}}>
                  <img src={require('../images/users.png')} style={{width:'100%',height:'100%',borderRadius:'50%'}} alt="" />
                
               
                  <div class="dropdown-menu p-2">
                    <div class='d-flex align-items-center gap-2'> 
                      <p><i class="bi bi-gear "></i> </p>  
                      <p>Setting</p>
                    </div>
                    <div class='d-flex align-items-center gap-2'> 
                      <p><i class="bi bi-bell"></i></p>  
                      <p>Notification</p>
                    </div> 
                    <div class='d-flex align-items-center gap-2'> 
                      <p><i class="bi bi-person-fill"></i></p>  
                      <p>Log-In</p>
                    </div>
                    <div class='d-flex align-items-center gap-2'> 
                      <p><i class="bi bi-person-fill"></i></p>  
                      <p>Log-Out</p>
                    </div>
             
                  </div>
                </div> 
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="navbar" aria-labelledby="navbarLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="navbarLabel"> </h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
              <div class='my-2'>
                <button class='btn btn-primary d-flex align-items-center gap-2 m-auto' data-bs-dismiss="offcanvas" aria-label="Close" ><i class="bi bi-person fs-5"></i> User</button>
              </div> 
              <div class='my-2'>
                <button class='btn btn-primary d-flex align-items-center gap-2 m-auto' data-bs-dismiss="offcanvas" aria-label="Close" ><i class="bi bi-gear fs-5"></i> Setting</button>
              </div>
              <div class='my-2'>
                <button class='btn btn-primary d-flex align-items-center gap-2 m-auto' data-bs-dismiss="offcanvas" aria-label="Close" ><i class="bi bi-megaphone fs-5"></i> Notification</button>
              </div>
          </div>
        </div>
    </div>
  )
}

export default Navbar