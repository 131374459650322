import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const ApaxChart = ({ chartData }) => {
  const [getData, setGetData] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setGetData(chartData || []);
        } else {
          setGetData([]);
        }
      },
      { rootMargin: '0px', threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, chartData]);

 

  const labels = getData?.category;
  const barType = getData?.type;
  const barTitle = getData?.title;
  const barData = getData?.data 
  const barBackgroundColor = getData?.backgroundColor;
 

  const dynamicOptions = {
    series: bar(),
    chart: {
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: getData?.name,
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels ,
    },
  };

  function bar() {
    let arr = [];
    for (let i = 0; i < barTitle?.length; i++) {
      arr.push({
        type: barType[i],
        name: barTitle[i],
        data: barData[i],
        // color: barBackgroundColor[i],
 
      });
    }
    return arr;
  }

  return (
    <div ref={ref}>
      <Chart options={dynamicOptions} series={bar()} height={350} />
    </div>
  );
};

export default ApaxChart ;
