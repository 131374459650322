import React from 'react' 
 
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';  
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
 
 
import Category1 from './elements/Category1'
import Category2 from './elements/Category2'
import Category3 from './elements/Category3'
import Category4 from './elements/Category4'
import Category5 from './elements/Category5'
import Category6 from './elements/Category6';
 



const Dashboard = () => {
  return (
    <div>
      <div class='col-11 m-auto'>  
        <div class='my-4'>
          <Category1/>
        </div> 
        <div class='my-4'> 
          <Category2/>
        </div>
        <div class='my-4'>
          <Category3/> 
        </div> 
        <div class='my-4'>
          <Category4/>
        </div> 
        <div class='my-4'>
          <Category5/>
        </div>  
        <div class='my-4'>
          <Category6/>
        </div>  
      </div>
        
    </div>
  )
}

export default Dashboard