import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
import '../style.css'
import { Color } from '../../theme/theme'
import CountingText from './CountingText'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import axios from 'axios';
 
 

const Category2 = () => {
    const [showMore, setShowMore] = useState(false)  

    const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const category2Ref = useRef(null);

    const [panelReportData, setPanelReportData] = useState()
    const [panelButtonArray, setPanelButtonArray] = useState()
    const [filterPanelReport, setFilterPanelReport] = useState()
    const [filterPanelReportData, setFilterPanelReportData] = useState()
    const [totalFeeding, setTotalFeeding] = useState()
    const [totalFeedingPre, setTotalFeedingPre] = useState()

    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

    useEffect(() => {
        const PReportButton = panelReportData?.map((el) => el.name)
        const uniqueSetButton = new Set(PReportButton);
        setPanelButtonArray(Array.from(uniqueSetButton));
    }, [panelReportData])

    useEffect(() => {
        setFilterPanelReport(panelButtonArray?.[0])
    }, [panelButtonArray, panelReportData])

    useEffect(() => {
        setFilterPanelReportData(panelReportData?.filter((el) => el.name == filterPanelReport))
    }, [filterPanelReport, panelReportData])


    function handleCallback(start, end) {
        const formattedStartDate =  moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        category2Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }
 

    useEffect(() => {
        const options = {
            startDate: startDate,
            endDate: endDate,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category2').daterangepicker(options, handleCallback); 
        handleCallback(startDate, endDate);  
    }, []);

    useEffect(() => {
        axios.post('https://utpann.in:3000/FeedingMaterialRecieved', { sdate: startDate, edate: endDate })
            .then((res) => {
                setPanelReportData(res.data?.result?.feeding_material_recieved);
                setTotalFeeding(res.data?.result?.feeding_total_section)
                setTotalFeedingPre(res.data?.result?.per_feeding_total_section)

            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate, endDate])

    return (
        <div class=''>
            <div class='row col-12 m-auto gap-lg-0 gap-4 '>
                <div class='col-lg-8 bg-white text-start rounded-4 ' data-aos="fade-up">
                    <div class='p-3'>
                        <div class='row align-items-center justify-content-between gap-lg-0 gap-3'>
                            <div class='col-lg-6'>
                                <p class='fw-bold'>Feeding Panel Report / Material Recieved</p>
                            </div>
                            <div class='col-lg-6 text-end d-flex justify-content-end'> 
                                <div ref={category2Ref} id="category2" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc', width: 'fit-content' }}>
                                <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                                </div>
                            </div>
                        </div>
                        <div class='my-3 panleSlider '>
                            {panelButtonArray?.map((el) => {
                                return (
                                    <button class={el == filterPanelReport ? 'btn panelBtnActive' : 'btn panelBtn'} onClick={() => setFilterPanelReport(el)}>
                                        {el}
                                    </button>
                                )
                            })}
                        </div>

                        <div class='row'>
                            {/* {filterPanelReportData?.map((el) => {
                                return (
                                    <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                        <div style={{ width: '60px', border: `2px solid ${el.color}`, borderRadius: '20px' }}></div>
                                        <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>{el.title}</p>
                                        <p class='fs-4 mb-1'>{<CountingText num={(el.number)} />}</p>
                                    </div>
                                )
                            })

                            } */}

                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #11B5FC`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Opening Stock</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.opening_stock} num={Number(filterPanelReportData?.[0]?.opening_stock)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #3FD091`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total Waste Recieved</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.total_waste_received} num={Number(filterPanelReportData?.[0]?.total_waste_received)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #F53D7A`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total Recieved</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.total_received} num={Number(filterPanelReportData?.[0]?.total_received)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #814BB5`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total Material</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.tot_material} num={Number(filterPanelReportData?.[0]?.tot_material)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #F54830`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total Feed Material</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.tot_feeded_material} num={Number(filterPanelReportData?.[0]?.tot_feeded_material)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #804AB3`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total material Instock</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.tot_instock} num={Number(filterPanelReportData?.[0]?.tot_instock)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #F54830`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Total Cost of Raw Material</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.tot_cost_raw_material} num={Number(filterPanelReportData?.[0]?.tot_cost_raw_material)} />}</p>
                            </div>
                            <div class='col-lg-3 col-md-4 col-6 my-3 border-bottom '>
                                <div style={{ width: '60px', border: `2px solid #37C493`, borderRadius: '20px' }}></div>
                                <p class='text-secondary mt-2' style={{ fontSize: '12px' }}>Lum sum raw gas should generated</p>
                                <p class='fs-4 mb-1'>{<CountingText key={filterPanelReportData?.[0]?.lum_sum_raw_gas_generated} num={Number(filterPanelReportData?.[0]?.lum_sum_raw_gas_generated)} />}</p>
                            </div>

                        </div>
                    </div>

                </div>

                <div class='col-lg-4 col-12  px-lg-3 p-0 ' data-aos="fade-up">
                    <div class='bg-white text-start rounded-4 w-100 h-100'>
                        <div class={showMore ? 'prSiteDataAll' : 'prSiteData'}>
                            <div class='showMore p-3'>
                                <button type="button" class="btn btn-dark " onClick={() => setShowMore(!showMore)}><i class={`bi bi-chevron-${showMore ? 'up' : 'down'} fs-5`}></i> </button>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Opening Stock </p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.DodgerBlue }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.opening_stock} num={totalFeeding?.opening_stock} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_opening_stock} num={totalFeedingPre?.per_opening_stock} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Total Received(C)</p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.FernGreen }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.total_received} num={totalFeeding?.total_received} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_total_received} num={totalFeedingPre?.per_total_received} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Total Material(D) </p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.CerisePink }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.total_material} num={totalFeeding?.total_material} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_total_material} num={totalFeedingPre?.per_total_material} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Total Feeded Material(E)</p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.AmethystParpal }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.total_feeded_material} num={totalFeeding?.total_feeded_material} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_total_feeded_material} num={totalFeedingPre?.per_total_feeded_material} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Total Material in Stock(F)</p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.RedOrange }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.total_material_instock} num={totalFeeding?.total_material_instock} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_total_material_instock} num={totalFeedingPre?.per_total_material_instock} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'>Total Cost of Raw Material</p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.AmethystParpal }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.total_material_price} num={totalFeeding?.total_material_price} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_total_material_price} num={totalFeedingPre?.per_total_material_price} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <div class='m-auto p-3'>
                                <p class='fw-bold'> Lum sum raw gas should be generated/MT</p>
                                <div class='col-11 m-auto row mt-2 gap-3 align-content-center'>
                                    <div class='col-8 rounded-4' style={{ background: Color.CerisePink }}>
                                        <p class='fs-4 my-3 text-white fw-semibold'>{<CountingText key={totalFeeding?.lum_sum_raw_gas_generated} num={totalFeeding?.lum_sum_raw_gas_generated} />}</p>
                                    </div>
                                    <div class='col-3 rounded-4 d-flex align-items-center justify-content-center' style={{ background: Color.Honeysuckle }}>
                                        <p class='fw-semibold'>{<CountingText key={totalFeedingPre?.per_lum_sum_raw_gas_generated} num={totalFeedingPre?.per_lum_sum_raw_gas_generated} />}%</p>
                                    </div>
                                </div>
                            </div>

                            <br /><br />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Category2