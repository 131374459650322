import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
import ApaxChart from '../charts/ApaxChart'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const gasPurity = {
    name: 'Gas Purity',
    category: ['23-12-2023', '24-12-2023', '25-12-2023', '26-12-2023', '27-12-2023', '28-12-2023', '29-12-2023', '30-12-2023', '31-12-2023', '01-01-2024', '02-01-2024', '03-01-2024',],
    title: ["CH4", "CO2", "O2", 'H2S'],
    type: ['line', 'line', 'line'],
    data: [
        ['8.5', '8.6', '8.8', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '9'],
        ['5.5', '5.6', '5.5', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '6'],
        ['3', '3.1', '3', '3', '3', '3', '3', '3', '3', '3', '3.9', '4.1'],
        ['5', '5.1', '5', '5', '5', '5', '5', '5', '5', '5', '4.9', '5.1'],

    ],
    backgroundColor: ['#F4462F', '#124FF8', '#58BC5A',],
}

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const Category5 = () => {

    const [startDate, setStartDate] = useState(moment().subtract(29, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const category5Ref = useRef(null);
    const [gasPurityData, setGasPurityData] = useState()
    const [gasPurityChartData, setGasPurityChartData] = useState()


    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

    function handleCallback(start, end) {
        const formattedStartDate = moment(start).format('YYYY-MM-DD');
        const formattedEndDate = moment(end).format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        category5Ref.current.querySelector('span').innerHTML = `${formattedStartDate} - ${formattedEndDate}`;
    }


    useEffect(() => {
        const options = {
            startDate: startDate,
            endDate: endDate,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        $('#category5').daterangepicker(options, handleCallback);
        handleCallback(startDate, endDate);
    }, []);

    useEffect(() => {
        axios.post('https://utpann.in:3000/GasPurity', { sdate: startDate, edate: endDate })
            .then((res) => {

                setGasPurityData(res.data?.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [startDate, endDate])

    useEffect(() => {
        let category = []
        let ch4 = []
        let co2 = []
        let h2s = []
        let o2 = []
        for (let i = 0; i < gasPurityData?.gas_purity?.length; i++) {
            category.push(gasPurityData?.gas_purity?.[i]?.c_date == null ? 0 : gasPurityData?.gas_purity?.[i]?.c_date)
            ch4.push(gasPurityData?.gas_purity?.[i]?.avg_ch4 == null ? 0 : gasPurityData?.gas_purity?.[i]?.avg_ch4)
            co2.push(gasPurityData?.gas_purity?.[i]?.avg_co2 == null ? 0 : gasPurityData?.gas_purity?.[i]?.avg_co2)
            h2s.push(gasPurityData?.gas_purity?.[i]?.avg_h2s == null ? 0 : gasPurityData?.gas_purity?.[i]?.avg_h2s)
            o2.push(gasPurityData?.gas_purity?.[i]?.avg_o2 == null ? 0 : gasPurityData?.gas_purity?.[i]?.avg_o2)
        }
        setGasPurityChartData({
            name: ' ',
            category: category,
            title: ["CH4", "CO2", "O2", 'H2S'],
            type: ['line', 'line', 'line', 'line'],
            data: [
                ch4, co2, o2, h2s

            ],

        })

    }, [gasPurityData])

    return (
        <div>
            <div class='bg-white rounded-3 p-sm-3 text-start' data-aos="fade-up">
                <div class='p-3'>
                    {/* <p class='fw-bold'> Gas Purity</p>  */}
                    <div class='row align-items-center justify-content-between gap-lg-0 gap-3 text-start'>
                        <div class='col-lg-6'>
                            <p class='fw-bold'>Gas Purity</p>
                        </div>
                        <div class='col-lg-6 text-end d-flex justify-content-end '>
                            <div  ref={category5Ref} id="category5" style={{ background: '#fff', cursor: 'pointer', padding: '5px 10px', border: '1px solid #ccc',width:'fit-content'  }}>
                            <i class="bi bi-calendar-week-fill"></i>
                                <span class='px-2'></span> <i class="bi bi-caret-down-fill"></i>
                            </div>
                        </div>
                    </div>

                    <div class='my-4 row'>
                        <div class='col-lg-3 col-md-6 my-2'>
                            <div class='col-11 m-auto d-flex align-items-center justify-content-between px-3 p-1 rounded-3' style={{ background: '#58BC5A' }}>
                                <div>
                                    <p style={{ fontSize: '12px', fontWeight: '600' }}>Ch4</p>
                                    <p class='fs-4 fw-bold text-white'>{gasPurityData?.ch4}</p>
                                </div>
                                <div>
                                    <p><i class="bi bi-fuel-pump-fill text-white"></i></p>
                                </div>
                            </div>
                        </div>

                        <div class='col-lg-3 col-md-6 my-2'>
                            <div class='col-11 m-auto d-flex align-items-center justify-content-between px-3 p-1 rounded-3' style={{ background: '#58BC5A' }}>
                                <div>
                                    <p style={{ fontSize: '12px', fontWeight: '600' }}>Co2</p>
                                    <p class='fs-4 fw-bold text-white'>{gasPurityData?.co2}</p>
                                </div>
                                <div>
                                    <p><i class="bi bi-fuel-pump-fill text-white"></i></p>
                                </div>
                            </div>
                        </div>

                        <div class='col-lg-3 col-md-6 my-2'>
                            <div class='col-11 m-auto d-flex align-items-center justify-content-between px-3 p-1 rounded-3' style={{ background: '#58BC5A' }}>
                                <div>
                                    <p style={{ fontSize: '12px', fontWeight: '600' }}>O2</p>
                                    <p class='fs-4 fw-bold text-white'>{gasPurityData?.o2}</p>
                                </div>
                                <div>
                                    <p><i class="bi bi-fuel-pump-fill text-white"></i></p>
                                </div>
                            </div>
                        </div>

                        <div class='col-lg-3 col-md-6 my-2'>
                            <div class='col-11 m-auto d-flex align-items-center justify-content-between px-3 p-1 rounded-3' style={{ background: '#58BC5A' }}>
                                <div>
                                    <p style={{ fontSize: '12px', fontWeight: '600' }}>H2s</p>
                                    <p class='fs-4 fw-bold text-white'>{gasPurityData?.h2s}</p>
                                </div>
                                <div>
                                    <p><i class="bi bi-fuel-pump-fill text-white"></i></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <ApaxChart chartData={gasPurityChartData} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Category5