import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'daterangepicker';
import ApaxChart from '../charts/ApaxChart'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const gasPurity = {
    name: 'Gas Purity',
    category: ['23-12-2023', '24-12-2023', '25-12-2023', '26-12-2023', '27-12-2023', '28-12-2023', '29-12-2023', '30-12-2023', '31-12-2023', '01-01-2024', '02-01-2024', '03-01-2024',],
    title: ["CH4", "CO2", "O2", 'H2S'],
    type: ['line', 'line', 'line'],
    data: [
        ['8.5', '8.6', '8.8', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '8.9', '9'],
        ['5.5', '5.6', '5.5', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '5.9', '6'],
        ['3', '3.1', '3', '3', '3', '3', '3', '3', '3', '3', '3.9', '4.1'],
        ['5', '5.1', '5', '5', '5', '5', '5', '5', '5', '5', '4.9', '5.1'],

    ],
    backgroundColor: ['#F4462F', '#124FF8', '#58BC5A',],
}

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

const Category6 = () => {

    const [startDate, setStartDate] = useState(moment().subtract(29, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const category6Ref = useRef(null);
    const [stok, setStok] = useState()
    const [gasPurityChartData, setGasPurityChartData] = useState()


    useEffect(() => {
        AOS.init({ duration: 2000 });
    })

 


    useEffect(() => {
        axios.get('https://utpann.in:3000/GetProductStock')
            .then((res) => {
                setStok(res.data?.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        let category = []
        let stock = []

        for (let i = 0; i < stok?.length; i++) {
            category.push(stok?.[i]?.name == null ? 0 : stok?.[i]?.name)
            stock.push(stok?.[i]?.stockqty == null ? 0 : stok?.[i]?.stockqty)

        }
        setGasPurityChartData({
            name: ' ',
            category: category,
            title: ["Stock"],
            type: ['line'],
            data: [
                stock

            ],

        })

    }, [stok])

    return (
        <div>
            <div class='bg-white rounded-3 p-sm-3 text-start' data-aos="fade-up">
                <div class='p-3'>
                    {/* <p class='fw-bold'> Gas Purity</p>  */}
                    <div class='row align-items-center justify-content-between gap-lg-0 gap-3 text-start'>
                        <div class='col-lg-6'>
                            <p class='fw-bold'>Product Stock</p>
                        </div>
                    </div>

                    <div class='my-4 row'>
                        {
                            stok?.map((el) => {
                                return (
                                    <div class='col-lg-3 col-md-6 my-2'>
                                        <div class='col-11 m-auto d-flex align-items-center justify-content-between px-3 p-1 rounded-3' style={{ background: '#58BC5A' }}>
                                            <div>
                                                <p style={{ fontSize: '12px', fontWeight: '600' }}>{el.name}</p>
                                                <p class='fs-4 fw-bold text-white'>{el?.stockqty}</p>
                                            </div>
                                            <div>
                                                <p><i class="bi bi-graph-up text-white"></i> </p>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                    <div>
                        <ApaxChart chartData={gasPurityChartData} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Category6