import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
// In your index.js or App.js file
import 'daterangepicker/daterangepicker.css';

function App() {
  return (
    <div className="App">
        <Navbar/>
        <div style={{marginTop:'100px'}}>
          <Dashboard/>
        </div>
    </div>
  );
}

export default App;
