import React, { useState, useEffect, useRef } from 'react';

const CountingText = ({ num }) => {

  const [targetValue, setTargetValue] = useState()
  // const targetValue = num // Target value to reach
  const duration = 2000; // Duration of the counting animation in milliseconds
  const steps = 50; // Number of steps in the animation

  const [count, setCount] = useState(0);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const countingRef = useRef(null);

  const [ans, setAns] = useState()

  useEffect(() => {
    setTargetValue(num)
  }, [num])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationTriggered) {
          let step = 0;
          const stepValue = targetValue / steps;
          const stepDuration = duration / steps;

          const intervalId = setInterval(() => {
            step++;
            setCount((prevCount) => prevCount + stepValue);

            if (step >= steps) {
              clearInterval(intervalId);
              setCount(targetValue);
              setAnimationTriggered(true);
            }
          }, stepDuration); 
          return () => clearInterval(intervalId);
        }
      },
      { threshold: 0.5 }
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => observer.disconnect();
  }, [targetValue, duration, steps, animationTriggered]);


  const formattedCount =
    count !== undefined
      ? Math.abs(count) < 1
        ? count.toFixed(2).replace(/^0+/, '') // Handle the case when the entire number is less than 1
        : count.toLocaleString('en-US', {
          minimumIntegerDigits: 1, // Set minimumIntegerDigits to 1 to remove leading zeros
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null;


  return <span ref={countingRef}>{formattedCount}</span>;
};

export default CountingText;
